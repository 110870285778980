<template>
  <div>
    <loginDialog @loginSuccess="loginSuccess"></loginDialog>
  </div>
</template>

<script>
import loginDialog from "~hph/components/common/loginDialog";
export default {
  metaInfo() {
    return {
      title: this.$t("sign"),
    };
  },
  components: { loginDialog },
  data() {
    return {};
  },
  computed: {},
  methods: {
    loginSuccess(){
      this.$router.push('/')
    }
  },
};
</script>
