import { render, staticRenderFns } from "./sign.vue?vue&type=template&id=f992fd98&"
import script from "./sign.vue?vue&type=script&lang=js&"
export * from "./sign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports